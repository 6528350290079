<template>
  <BaseCard is-small-height>
    <template #content>
      <BaseAlert
        type="info"
        text="Раздел находится в процессе разработки"
      />
    </template>
  </BaseCard>
</template>

<script>
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseCard from '@/components/base/BaseCard'

export default {
  name: 'PassportVerification',
  components: { BaseCard, BaseAlert }
}
</script>